<template>
  <div>
    <div
      id="preview"
      class="ml-auto mr-auto"
    >
      <div class="image">
        <img src="/media/hr/welcome.jpg">
      </div>
      <div class="employee-image">
        <img
          :src="getFormData.image"
          alt=""
        >
      </div>
      <div class="yellow-bar">
        <div class="center">
          <div class="employee-name">
            <h3>{{ getFormData.text1 }}</h3>
          </div>
          <div class="employee-title">
            <h5>{{ getFormData.text2 }} <div>Olarak Gücümüze Güç Katmaya <br> İnallar & Makyağsan Ailesine Hoş Geldin!</div></h5>
          </div>
        </div>
      </div>
      <div class="employee-summary-parent">
        <div
          class="content-text-item"
          v-html="getFormData.text3"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  computed: {
    getFormData() {
      return this.$store.getters['hrNotifications/getHr_notification']
    },
  },
}
</script>
<style>
.employee-summary-parent .content-text-item p{
  line-height: 24px !important;
  margin: 0 !important;
  padding: 0 !important;
}
</style>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@300;400;600&display=swap');
#preview{
  width: 800px;
  height: 800px;
  position: relative;
  overflow: hidden;
}
.employee-image {
  position: absolute;
  top: 57px;
  left: 25px;
}
.employee-image img {
  width: 150px;
}
.yellow-bar {
  width: 800px;
  height: 202px;
  position: absolute;
  top: 47px;
  left: 0;
}
.yellow-bar .center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 200px;
}
.employee-name h3 {
  font-family: 'Exo', sans-serif;
  font-size: 22px;
  color: #000;
  font-weight: 600;
}
.employee-title {
  width: 350px;
}
.employee-title h5 {
  font-family: 'Exo', sans-serif;
  font-size: 16px;
  color: #000;
  margin: 0;
  font-weight: 300;
}
.employee-summary-parent {
  position: absolute;
  top: 251px;
  left: 0;
  width: 100%;
  height: 450px;
  padding: 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.employee-summary-parent .content-text-item {
  margin: 0;
  color: #000;
  font-size: 16px;
  font-family: 'Exo', sans-serif;
}
</style>
